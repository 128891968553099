import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import { muiTheme } from './utils';

ReactDOM.render(
  <Fragment>
    <ThemeProvider theme={muiTheme}>
      <App />
    </ThemeProvider>
  </Fragment>,
  document.getElementById('root'),
);
