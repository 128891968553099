import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// MUI
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// others
import { CONTACT_EMAIL, MAIL_TO, COPYRIGHT, PP_URL } from '../constants';
import { resolveFooterCoverURL, resolveImageStyle } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    position: 'relative',
    bottom: '-20%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  emailLink: {
    fontWeight: 'bold',
    color: '#111', // visited時に色が青くなるのを無効化
    height: 400,
  },
  emailText: {
    textDecoration: 'none',
    lineHeight: '80px',
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    '&:hover, &:focus': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  copyright: {
    fontSize: '0.7rem',
    color: '#000',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  privacyPolicy: {
    paddingLeft: theme.spacing(1),
    textDecoration: 'underline',
  },
}));

const App = () => {
  const c = useStyles();
  const desktop = useMediaQuery('(min-width:900px)');
  const imageURL = resolveFooterCoverURL(desktop);
  const height = desktop ? 400 : 250;
  const imageStyle = resolveImageStyle({ desktop, imageURL, backgroundPosition: '50% 100%', height });

  const Content = () => (
    <div className={c.content}>
      <a className={c.emailLink} href={MAIL_TO}>
        <div className={c.emailText}>{CONTACT_EMAIL}</div>
      </a>
      <Typography variant="body2" className={c.copyright}>
        {COPYRIGHT}
        <a href={PP_URL}>
          <span className={c.privacyPolicy}>Privacy Policy</span>
        </a>
      </Typography>
    </div>
  );

  // dekstopの時、背景画像を描画
  return desktop ? (
    <div id="footer" style={imageStyle}>
      <Content />
    </div>
  ) : (
    <div id="footer">
      <Content />
    </div>
  );
};

export default App;
