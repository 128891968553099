import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InstagramEmbed from "react-instagram-embed";
import { INSTAGRAM_CLIENT_TOKEN } from "../constants";
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1000,
    margin: 0,
  },
  dialogContent: {
    maxWidth: 640,
  },
  icon: {
    width: "30px",
    height: "30px",
    opacity: 1,
  },
  closeIconButton: {
    display: "inline-block",
    width: "56px",
    height: "56px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },
  adjustment: {
    width: "100%",
    height: "80px",
  },
}));

const PhotoDialog = ({ toggleDrawer, state }) => {
  const c = useStyles();
  const { dialog: open, photo } = state;
  return (
    <div className={c.root}>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={toggleDrawer("dialog", false, photo)}
        aria-labelledby="form-dialog-title"
      >
        <IconButton
          onClick={toggleDrawer("dialog", false, photo)}
          className={c.closeIconButton}
          aria-label="HeaderMenu"
        >
          <CloseIcon className={c.icon} />
        </IconButton>
        <div className={c.adjustment} />
        <DialogContent className={c.dialogContent}>
          <div>
            <InstagramEmbed
              url={photo.permalink}
              clientAccessToken={INSTAGRAM_CLIENT_TOKEN}
              maxWidth={640}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PhotoDialog;
