// react
import React, { Fragment } from 'react';
// mui
import { makeStyles } from '@material-ui/core/styles';
// mui component
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AppleIcon from '@material-ui/icons/Apple';
// mui peripheral
import { Icon as Iconify } from '@iconify/react';
import SpotifyIcon from '@iconify/icons-mdi/spotify';
import BandcampIcon from '@iconify/icons-fa/bandcamp';
import {
	APPLE_MUSIC_LINK,
	SPOTIFY_LINK,
	BANDCAMP_LINK,
	INSTAGRAM_LINK,
	TWITTER_LINK,
	YOUTUBE_PAGE
} from '../constants';
// components
// others

const useStyles = makeStyles((theme) => ({
	// icons: 左下
	icons: {
		zIndex: 2000,
		position: 'fixed',
		bottom: 30,
		right: 30
	},
	iconButton: {
		width: '56px',
		height: '56px',
		color: '#111',
		marginBottom: '6px',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	iconButtonAwesome: {
		width: '56px',
		height: '56px',
		color: '#111',
		marginBottom: '6px'
	},
	icon: {
		zIndex: 100,
		width: '54px',
		height: '54px',
		opacity: 1,
		border: '2px solid #111',
		padding: theme.spacing(1)
	},
	spotifyIcon: {
		width: '54px',
		height: '54px',
		opacity: 1,
		border: '2px solid #111',
		padding: theme.spacing(1)
	}
}));

const iconifyStyle = {
	width: '54px',
	height: '54px',
	border: '2px solid #111',
	padding: '8px',
	margin: '0px -12px',
	zIndex: 100
};
const HeaderIconsD = () => {
	const c = useStyles();
	return (
		<Fragment>
			{/* icons: 右上 */}
			<div className={c.icons}>
				<div>
					<Link target="_blank" href={APPLE_MUSIC_LINK}>
						<IconButton className={c.iconButton} aria-label="HeaderMenu">
							<AppleIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
				<div>
					<Link target="_blank" href={SPOTIFY_LINK}>
						<IconButton disableRipple className={c.iconButton} aria-label="HeaderMenu">
							<Iconify icon={SpotifyIcon} style={iconifyStyle} />
						</IconButton>
					</Link>
				</div>
				<div>
					<Link target="_blank" href={BANDCAMP_LINK}>
						<IconButton disableRipple className={c.iconButton} aria-label="HeaderMenu">
							<Iconify icon={BandcampIcon} style={iconifyStyle} />
						</IconButton>
					</Link>
				</div>
				<div>
					<Link target="_blank" href={TWITTER_LINK}>
						<IconButton disableRipple className={c.iconButton} aria-label="HeaderMenu">
							<TwitterIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
				<div>
					<Link target="_blank" href={INSTAGRAM_LINK}>
						<IconButton disableRipple className={c.iconButton} aria-label="HeaderMenu">
							<InstagramIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
				<div>
					<Link target="_blank" href={YOUTUBE_PAGE}>
						<IconButton disableRipple className={c.iconButton} aria-label="HeaderMenu">
							<YouTubeIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
			</div>
		</Fragment>
	);
};
export default HeaderIconsD;
