import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { isWidthDown } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		boxSizing: 'border-box',
		fontWeight: 600,
		margin: 0,
		padding: 0,
		borderBottom: 'solid 0.1px black',
		paddingBottom: theme.spacing(0.5),
		[theme.breakpoints.up('lg')]: {
			fontSize: '3.3rem',
			marginTop: theme.spacing(12),
			marginBottom: theme.spacing(8)
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.2rem',
			paddingTop: theme.spacing(12),
			marginBottom: theme.spacing(4)
		}
	}
}));

const SectionTitle = ({ name }) => {
	const c = useStyles();
	return (
		<Typography variant="h4" component="h3" className={c.root}>
			{name}
		</Typography>
	);
};

export default SectionTitle;
