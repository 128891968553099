/**
 * 文字列をカウントする
 * 半角は1文字、全角は2文字でカウントする
 * @param {*} strSrc
 * @returns
 */
const countStringLengthByMultiByte = (strSrc) => {
  let len = 0;
  strSrc = escape(strSrc);
  for (let i = 0; i < strSrc.length; i++, len++) {
    if (strSrc.charAt(i) === "%") {
      if (strSrc.charAt(++i) === "u") {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
};

export const trimString = (str, strLimit) => {
  let isSlice = false;

  while (countStringLengthByMultiByte(str) > strLimit) {
    str = str.slice(0, str.length - 1);
    isSlice = true;
  }

  if (isSlice) {
    str += "...";
  }

  return str;
};
