import { useState, useEffect } from "react";
import { fetchPhotos } from "../requests";

const initialPhoto = {
  id: '17924770922525683',
  owner: { id: '17841407323977767' },
  caption: 'Thanks for all team members who involved with making the music video of our brand new single - “no one”\n' +
    'We appreciate it. \n' +
    '\n' +
    'NOIGTSYのミュージックビデオ制作に関わってくれた皆様、ありがとうございました。\n' +
    '\n' +
    'No One is Going to Save You: https://youtu.be/5AO08mTgND8\n' +
    '\n' +
    'video link in bio\n' +
    '\n' +
    'photo by @denjiroudai',
  username: 'welovejohnnivan',
  media_url: 'https://scontent-lax3-1.cdninstagram.com/v/t51.29350-15/302189448_516347016916404_1732232036338238790_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQhEaPBh9MwAX9UwuTC&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AT_A5GmOzxtqr2E1RJ_2bOk0jnaiOF49F-pzdgf9UWYjEg&oe=6315DC38',
  permalink: 'https://www.instagram.com/p/Ch9xiA9rxRV/',
  shortcode: 'Ch9xiA9rxRV',
  timestamp: '2022-09-01T13:07:58+0000',
  like_count: 44,
  media_type: 'CAROUSEL_ALBUM',
  comments_count: 0,
  is_comment_enabled: true,
  media_product_type: 'FEED'
}
const initialPhotos = [initialPhoto,initialPhoto,initialPhoto,initialPhoto];

export const usePhoto = () => {
  const [photos, setPhotos] = useState(initialPhotos);

  const [photoAttribute, setPhotoAttribute] = useState({
    index: 0,
    onPrev: false,
    onNext: true,
  });

  const handleScroll = (num) => {
    if (num === -1) {
      const index = photoAttribute.index - 4;
      const onPrev = index > 0;
      const onNext = index + 4 < photos.length;
      setPhotoAttribute({
        index: index,
        onPrev,
        onNext,
      });
    }
    if (num === 1) {
      const index = photoAttribute.index + 4;
      const onPrev = index > 0;
      const onNext = index + 4 < photos.length;
      setPhotoAttribute({
        index: index,
        onPrev,
        onNext,
      });
    }
  };
  const handlePrev = () => handleScroll(-1);
  const handleNext = () => handleScroll(1);

  // livesの変更を処理
  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      const response = await fetchPhotos();
      if (!didCancel && response && response.length) {
        setPhotos(response);
      }
    };
    fetch();
    return () => {
      didCancel = true;
    };
  }, []);

  return {
    photos,
    handlePrev,
    handleNext,
    photoAttribute,
  };
};
