import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import DiscographyItem from './DiscographyItem';
import DiscographyDrawer from './DiscographyDrawer';
import DiscographyDialog from './DiscographyDialog';
import { DISCOGRAPHY } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Discography = () => {
  const c = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  // state concern
  const [state, setState] = React.useState({
    drawer: false,
    dialog: false,
  });

  // on state change
  const toggleDrawer = (kind, open, discography) => () => {
    if (discography) setState({ ...state, [kind]: open, discography });
    else setState({ ...state, [kind]: open });
  };

  return (
    <div className={c.root}>
      {desktop && <DiscographyDialog toggleDrawer={toggleDrawer} state={state} />}
      {!desktop && <DiscographyDrawer toggleDrawer={toggleDrawer} state={state} />}
      {/* spacingの単位は4px */}
      <Grid container spacing={desktop ? 6 : 3} alignContent="space-around">
        {DISCOGRAPHY.map((discography) => (
          <DiscographyItem onClick={toggleDrawer} key={discography.title} discography={discography} />
        ))}
      </Grid>
    </div>
  );
};

export default Discography;
