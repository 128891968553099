import { IMAGE_URL } from "../constants";


// カバー画像
const COVER_PREFIX = {
  desktop: "",
  mobile: "-xs",
  "mobile-landscape": "-xs",
  "ipad-landscape": "-mdl",
};
export const resolveCoverURL = (dtype) =>`${IMAGE_URL}global/cover2024${COVER_PREFIX[dtype]}.jpeg?hl=ja`;
const getPrefix = (isDesktop) => (isDesktop ? "" : "-xs");
export const resolveFooterCoverURL = (desktop = false) =>
  `${IMAGE_URL}global/cover${getPrefix(desktop)}.jpeg?hl=ja`;

// 背景画像
export const resolveNamedCoverURL = (name) =>
  `${IMAGE_URL}global/${name}.jpeg?hl=ja`;

// ロゴURL
export const resolveLogoURL = () => `${IMAGE_URL}global/logowhite.svg`;

export const resolveImageStyle = ({
  imageURL,
  desktop = false,
  backgroundPosition = null,
  height = null,
}) =>
  desktop
    ? {
        backgroundImage: `url(${imageURL})`,
        width: "100%",
        height: height || 600,
        backgroundPosition: backgroundPosition,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }
    : {
        backgroundImage: `url(${imageURL})`,
        width: "100%",
        height: height || 200,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "contain",
      };
