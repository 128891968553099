import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Album } from './Album';

const useStyles = makeStyles({
  fullList: {
    width: 'auto',
    height: 'auto',
  },
});

const DiscographyDrawer = ({ state, toggleDrawer }) => {
  const c = useStyles();
  const { discography } = state;
  const fullList = (side) => (
    <div
      className={c.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false, discography)}
      onKeyDown={toggleDrawer(side, false, discography)}
    >
      <Album kind="drawer" discography={discography} />
    </div>
  );

  return (
    <Fragment>
      <Drawer
        anchor="bottom"
        open={state.drawer}
        onClose={toggleDrawer('drawer', false, discography)}
        transitionDuration={{ enter: 500, exit: 250 }}
      >
        {fullList('drawer')}
      </Drawer>
    </Fragment>
  );
};

export default DiscographyDrawer;
