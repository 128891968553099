import React, { useState, Fragment } from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import ButtonBase from "@material-ui/core/ButtonBase";
import PhotoDialog from "./PhotoDialog";
import PhotoDrawer from "./PhotoDrawer";
import { isPresent } from "../utils";
import { PhotoItem } from "./PhotoItem";
import { usePhoto } from "../hooks";
import { useDisplaySize } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    // gridRowGap: "10px",
    // gridColumnGap: "1%",
  },
  // CONCERN: prev button, next button
  onPrevNext: {
    width: 30,
    height: 60,
    position: "absolute",
    top: "41.5%",
    zIndex: 1200,
    opacity: 0.8,
    background: "rgba(24, 60, 91, 0.8)",
    [theme.breakpoints.up("lg")]: {
      width: 45,
      height: 90,
    },
    boxShadow: "-2px 0 5px rgba(0,0,0,0.3)",
  },
  onPrev: {
    left: "0%",
    borderRadius: "0px 100px 100px 0px",
  },
  onNext: {
    right: "0",
    borderRadius: "100px 0 0 100px",
  },
  onPrevNextIcon: {
    marginTop: "2px",
    color: "#fefefe",
    width: "2rem",
    height: "2rem",
  },
}));

export const Photo = () => {
  const c = useStyles();
  const { desktop } = useDisplaySize();
  const { photos, handlePrev, handleNext, photoAttribute } = usePhoto();
  // state concern
  const [state, setState] = useState({
    drawer: false,
    dialog: false,
    photo: photos && photos[0] ? photos[0] : null,
  });
  const toggleDrawer = (kind, open, photo) => () => {
    if (photo) setState({ ...state, [kind]: open, photo });
    else setState({ ...state, [kind]: open });
  };

  const Photos = () => {
    const index = photoAttribute.index;
    const records = photos;
    return (
      <Fragment>
        <PhotoItem photo={records[index]} index={0} onToggle={toggleDrawer} />
        <PhotoItem
          photo={records[index + 1]}
          index={1}
          onToggle={toggleDrawer}
        />
        <PhotoItem
          photo={records[index + 2]}
          index={2}
          onToggle={toggleDrawer}
        />
        <PhotoItem
          photo={records[index + 3]}
          index={3}
          onToggle={toggleDrawer}
        />
      </Fragment>
    );
  };

  return isPresent(photos) ? (
    <div>
      {desktop && <PhotoDialog toggleDrawer={toggleDrawer} state={state} />}
      {!desktop && <PhotoDrawer toggleDrawer={toggleDrawer} state={state} />}
      <Grid
        container
        spacing={0}
        alignContent="space-between"
        className={c.root}
      >
        {photoAttribute.onPrev && (
          <ButtonBase
            className={cx(c.onPrevNext, c.onPrev)}
            focusVisibleClassName={c.focusVisible}
            onClick={handlePrev}
          >
            <LeftIcon className={c.onPrevNextIcon} />
          </ButtonBase>
        )}
        {photoAttribute.onNext && (
          <ButtonBase
            className={cx(c.onPrevNext, c.onNext)}
            focusVisibleClassName={c.focusVisible}
            onClick={handleNext}
          >
            <RightIcon className={c.onPrevNextIcon} />
          </ButtonBase>
        )}
        {photos && photos.length && (
          <Photos />
        )}
      </Grid>
    </div>
  ) : null;
};
