// プライバシーポリシーのパス
export const PP_URL = `privacy-policy`;

// google cloud storage
export const IMAGE_URL =
  "https://storage.googleapis.com/johnnivan-static-assets/";

// Instagram APIアクセストークン(バックエンド)
export const INSTAGRAM_ACCESS_TOKEN =
  process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

// Instagram APIアクセストークン(フロント)
export const INSTAGRAM_CLIENT_TOKEN =
  process.env.REACT_APP_INSTAGRAM_CLIENT_TOKEN;

// Photo項目表示数(4の倍数である必要あり)
export const PHOTO_DISPLAY_COUNT = 32;

// コンタクト用のメールアドレス
export const CONTACT_EMAIL = "welovejohnnivan@gmail.com";
// emailへのリンク
export const MAIL_TO = `mailto:${CONTACT_EMAIL}`;
// コピーライト文言
export const COPYRIGHT = "Copyright © Johnnivan All Rights Reserved.";

// SNSのURL
export const YOUTUBE_PAGE =
  "https://www.youtube.com/channel/UC-e4OyD2-FUuITUeB51v8Ng";
export const INSTAGRAM_LINK =
  "https://www.instagram.com/welovejohnnivan/?hl=ja";
export const TWITTER_LINK = "https://twitter.com/welovejohnnivan";
export const BANDCAMP_LINK = "https://johnnivan.bandcamp.com/";
export const SPOTIFY_LINK =
  "https://open.spotify.com/artist/27JM9HvAAcjGJPZCoZLgWe";
export const APPLE_MUSIC_LINK =
  "https://music.apple.com/jp/artist/johnnivan/1387469938";
