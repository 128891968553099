// react
import React, { Fragment } from 'react';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';
// mui
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MovieIcon from '@material-ui/icons/YouTube';
import Grid from '@material-ui/core/Grid';
// di
import { NEW_ALBUMS, YOUTUBE_PAGE } from '../constants';
import { isPresent, renderSongName } from '../utils';

const useStyles = makeStyles((theme) => ({
  rootOnNew: {
    [theme.breakpoints.up('md')]: {
      border: 'solid 2px #111',
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
      borderRadius: 4,
    },
  },
  root: {
    [theme.breakpoints.up('md')]: {
      // 枠線はなしにする
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
  },
  cover: {
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    fontWeight: 600,
    display: 'block',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      fontSize: '24px',
    },
  },
  sectionTitle2ndLow: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      fontSize: '24px',
    },
  },
  contentTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    textAlign: 'center',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  albumSection: {
    maxWidth: '200px',
    margin: 'auto',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  button: {
    height: '40px',
    width: '210px',
    marginTop: theme.spacing(2),
    borderRadius: '1px',
    border: 'solid 1px #000',
    boxShadow: theme.shadows[0.1],
    fontWeight: 600,
  },
  buttonAppleMusic: {
    paddingBottom: theme.spacing(1),
  },
  songHeadline: {
    fontSize: '13px',
    fontWeight: 600,
    borderBottom: 'solid 1px #999',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: 400,
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  songTitle: {
    fontFamily: 'Helvetica',
    letterSpacing: -0.2,
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(0.5),
  },
  attributes: {
    marginTop: theme.spacing(2),
  },
  attribute: {
    fontSize: '12px',
    fontFamily: 'Helvetica Neue',
    color: '#707070',
  },
  movie: {
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      width: '100%',
      marginTop: theme.spacing(8),
    },
  },
  youtubeTmp: {
    background: '#eee',
    borderBox: 'box-sizing',
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  toMovie: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  toMovieText: {
    fontSize: '12px',
    fontWeight: 'lighter',
    letterSpacing: 0.25,
    lineHeight: '50px',
    display: 'flex',
    maxWidth: 960,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      lineHeight: '75px',
      fontSize: '18px',
    },
  },
  toMovieIcon: {
    height: 50,
    width: 50,
    [theme.breakpoints.up('md')]: {
      height: 75,
      width: 75,
    },
  },
  disableLinkStyle: {
    color: '#111',
  },
}));

export const NewAlbum = ({ discography, latest = false, kind = false }) => {
  const c = useStyles();
  const { headline, title, cover, songs, attributes, links, youtubeLinks,  isdefaultEnable, isTowerRecordEnable, isecsiteEnable, ispreorderEnable} = discography || NEW_ALBUMS[0];

  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const youtubeViewHeight = desktop ? 500 : 300;

  const coverStyle = {
    backgroundImage: `url(${cover})`,
    height: 200,
    width: 200,
    backgroundSize: 'cover',
  };
  return (
    <Fragment>
      {latest && mobile && (
        <>
          <Typography variant="h4" component="h3" className={c.sectionTitle}>
            {headline.mobile[0]}
          </Typography>
          <Typography variant="h4" component="h3" className={cx(c.sectionTitle, c.sectionTitle2ndLow)}>
            {headline.mobile[1]}
          </Typography>
        </>
      )}
      {latest && !mobile && (
        <>
          <Typography variant="h4" component="h3" className={cx(c.sectionTitle, c.sectionTitleD)}>
            {headline.desktop}
          </Typography>
        </>  
      )}
      <Grid container spacing={2} className={latest ? c.rootOnNew : c.root}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {desktop && (
          <Typography variant="h5" component="h4" className={c.contentTitle}>
          {title}
          </Typography>
          )}

        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={c.albumSection}>
            <LazyLoad>
              <div className={c.cover} style={coverStyle} />
            </LazyLoad>
          {isdefaultEnable && (
            <Link target="_blank" href={links.appleMusic}>
              <Button variant="outlined" className={cx(c.button, c.buttonAppleMusic)}>
                Apple Music
              </Button>
            </Link>
            )}
          {isdefaultEnable && (
            <Link target="_blank" href={links.spotify}>
              <Button variant="outlined" className={c.button}>
                Spotify
              </Button>
            </Link>
            )}
          {isdefaultEnable && (
            <Link target="_blank" href={links.bandcamp}>
              <Button variant="outlined" className={c.button}>
                bandcampで購入する
              </Button>
            </Link>
            )}
            {isTowerRecordEnable && (
              <Link target="_blank" href={links.towerRecords}>
                <Button variant="outlined" className={c.button}>
                  タワーレコードで購入する
                </Button>
              </Link>
            )}
            {ispreorderEnable && (
              <Link target="_blank" href={links.preorder}>
                <Button variant="outlined" className={c.button}>
                  プレオーダーする
                </Button>
              </Link>
          )}
        {isecsiteEnable && (
              <Link target="_blank" href={links.ecsite}>
              <Button variant="outlined" className={c.button}>
              OFFICIAL SHOPで購入する
            </Button>
          </Link>
        )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div>
            <Typography variant="h6" component="h6" className={c.songHeadline}>
              Songs
            </Typography>
            <List dense>
              {songs.map((song, index) => (
                <ListItem key={song} className={c.songTitle}>
                  <ListItemText disableTypography primary={renderSongName(index, song)} />
                </ListItem>
              ))}
            </List>
            <div className={c.attributes}>
              {attributes.map((attribute) => (
                <Typography key={attribute} variant="body2" className={c.attribute}>
                  {attribute}
                </Typography>
              ))}
            </div>
          </div>
        </Grid>
        {!desktop && isPresent(youtubeLinks) && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={c.movie}>
              {youtubeLinks.map((link) => (
                <div key={`youtube-${link}`} className={c.youtubeTmp}>
                  <iframe
                    title="johnnivan-mv"
                    width="100%"
                    height={youtubeViewHeight}
                    src={link}
                    frameBorder="0"
                    allow="autoplay; "
                    allowFullScreen
                  />
                </div>
              ))}
              <Link target="_blank" href={YOUTUBE_PAGE} className={c.disableLinkStyle}>
                <div className={c.toMovie}>
                  <Typography className={c.toMovieText}>watch on</Typography>
                  <MovieIcon className={c.toMovieIcon} />
                </div>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      {desktop && isPresent(youtubeLinks) && (
        <Fragment>
          <div className={c.movie}>
            {youtubeLinks.map((link) => (
              <div className={c.youtubeTmp}>
                <iframe
                  title="johnnivan-mv"
                  width="100%"
                  height={youtubeViewHeight}
                  src={link}
                  frameBorder="0"
                  allow="autoplay; "
                  allowFullScreen
                />
              </div>
            ))}
            <Link target="_blank" href={YOUTUBE_PAGE} className={c.disableLinkStyle}>
              <div className={c.toMovie}>
                <Typography className={c.toMovieText}>watch on</Typography>
                <MovieIcon className={c.toMovieIcon} />
              </div>
            </Link>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
