import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    margin: 0,
    padding: 0,
  },
  cover: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    background: '#111',
    cursor: 'pointer',
    '&:hover, &$focusVisible': {
      '& $backdrop': {
        opacity: 1,
      },
      '& $content': {
        opacity: 1,
      },
      '& $title': {
        opacity: 1,
      },
      '& $releasedAt': {
        opacity: 1,
      },
    },
  },
  backdrop: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.4)',
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.complex,
      delay: 50,
    }),
  },
  title: {
    opacity: 0,
    position: 'absolute',
    color: '#fefefe',
    left: '50%',
    cursor: 'pointer',
    '-ms-transform': 'translate(-50%,-50%)',
    '-webkit-transform': 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)',
    zIndex: 300,
    [theme.breakpoints.up('md')]: {
      top: '40%',
      width: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      top: '40%',
      width: '130px',
    },
  },
  titlePosition: {
    textAlign: 'center',
  },
  releasedAt: {
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    color: '#fefefe',
    left: '50%',
    '-ms-transform': 'translate(-50%,-50%)',
    '-webkit-transform': 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)',
    margin: 0,
    padding: 0,
    zIndex: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
      bottom: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      bottom: '20%',
    },
  },
}));

const DiscographyItem = ({ discography, onClick }) => {
  const c = useStyles();
  const { cover, title, releasedAt, titleFontSize } = discography;
  const xss = useMediaQuery('(max-width:400px)');
  const xs = useMediaQuery('(max-width:500px)');
  const mobile = useMediaQuery('(max-width:500px)');
  const kind = mobile ? 'drawer' : 'dialog';
  const Artwork = (px) => {
    const artworkStyle = {
      backgroundImage: `url(${cover})`,
      height: `${px}px`,
      width: `${px}px`,
      backgroundSize: 'cover',
    };
    const fontSize = mobile ? titleFontSize.mobile : titleFontSize.desktop;

    return (
      <Grid className={c.root} item xs={6} sm={6} md={4} lg={4}>
        <LazyLoad>
          <div className={c.cover} style={artworkStyle} onClick={onClick(kind, true, discography)}>
            <div className={c.backdrop}>
              <div className={c.title} style={{ fontSize }}>
                <p className={c.titlePosition}>{title}</p>
              </div>
              <p className={c.releasedAt}>{releasedAt}</p>
            </div>
          </div>
        </LazyLoad>
      </Grid>
    );
  };
  return xss ? Artwork(140) : xs ? Artwork(160) : Artwork(280);
};

export default DiscographyItem;
