import React from 'react';
export const BackgroundMargin = ({ height }) => {
	const style = {
		display: 'block',
		position: 'relative',
		width: '100%',
		height
	};
	return <div style={style} />;
};
