// react
import React from 'react';
// mui
import { makeStyles } from '@material-ui/core/styles';
import { resolveImageStyle } from '../utils';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	}
}));

export const SectionBlank = ({ imageURL, backgroundPosition = null }) => {
	const c = useStyles();
	const imageStyle = resolveImageStyle({ imageURL, desktop: true, backgroundPosition });
	return <div className={c.root} style={imageStyle} />;
};
