import { sortBy } from "lodash";
import { contentfulClient as client } from "../utils";
/**
 * ContentfulからLive情報を取得
 * @returns
 */
export const fetchLives = async () => {
  // コンテンツを取得
  const entries = await client.getEntries({
    content_type: "liveInformation",
  });
  // console.log("live.entries.items");
  // console.log(entries.items);
  // コンテンツがない時、空の配列を返す
  if (!entries || !entries.items || !entries.items.length) {
    return [];
  }

  // LIVE情報をオブジェクト化する
  const liveInfo = entries.items.map((item) => {
    const { title, date, place, content: contentSource, cover } = item.fields;
    const content = contentSource.split("\n"); // contentを表示用に整形

    return {
      title,
      date,
      place,
      content,
      cover,
    };
  });

  // LIVE情報をソートする
  const sorted = sortBy(liveInfo, "startDate");
  return sorted;
};
