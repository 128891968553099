import React, { Fragment } from 'react';

// 曲名をRenderingする
export const renderSongName = (index, text) => {
  const songText = `${index + 1}. ${text}`;
  if (index < 9) {
    return (
      <Fragment>
        <span style={{ visibility: 'hidden' }}>0</span>
        {songText}
      </Fragment>
    );
  }
  return songText;
};

// Tmp
export const renderSongTitle = (index, text) => {
  const songText = `${index + 1}. ${text}`;
  if (index < 9) {
    return (
      <Fragment>
        <span style={{ color: '#fff' }}>0</span>
        {songText}
      </Fragment>
    );
  }
  return songText;
};
