import React, { Fragment } from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { HeaderImageD } from "./components/HeaderImageD";
import { HeaderImageM } from "./components/HeaderImageM";
import Title from "./components/Title";
import { NewAlbum as Album } from "./components/NewAlbum";
import Lives from "./components/Lives";
import Discography from "./components/Discography";
import { Photo } from "./components/Photo";
import { SectionBlank } from "./components/SectionBlank";
import { resolveNamedCoverURL } from "./utils";
import { BackgroundMargin } from "./components/BackgroundMargin";
import { NEW_ALBUMS } from './constants';

import { useDisplaySize } from "./hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "Helvetica",
    background: (props) =>
      props.dtype === "desktop" ? "#fefefe" : "transparency",
  },
  sectionCommonD: {
    // desktopの時、コンテンツが中央になるように左右に幅を取る
    paddingBottom: theme.spacing(8),
    margin: "0 auto",
    maxWidth: 960,
  },
  sectionCommonM: {
    // mobileの時、コンテンツに固定のスペースを割り当てる
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    background: "#fefefe",
  },
  sectionTopD: {
    paddingTop: theme.spacing(16),
  },
  sectionTopM: {
    paddingTop: theme.spacing(0.5),
  },
  sectionNormal: {
    paddingTop: theme.spacing(4),
  },
}));
const App = () => {
  // ヘッダーイメージ
  const { dtype, coverURL } = useDisplaySize();
  const c = useStyles({ dtype });
  const [NEW_ALBUM1] = NEW_ALBUMS;
  return (
    <div className={c.root} id="top">
      {/* ヘッダー */}
      {dtype === "desktop" && <HeaderImageD imageURL={coverURL} />}
      {dtype !== "desktop" && <HeaderImageM imageURL={coverURL} />}
      {dtype !== "desktop" && <BackgroundMargin height={"100vh"} />}
      <Header />

      {/* ALBUM: New Single 1 */}
      {dtype === "desktop" && (
        <Fragment>
          <section
            id="newRelease"
            className={cx(c.sectionCommonD, c.sectionTopD)}
          >
            <Album latest key="latest1-desktop" discography={NEW_ALBUM1} />
          </section>
          <SectionBlank
            imageURL={resolveNamedCoverURL("subcover-live")}
            backgroundPosition="0% 0%"
          />
        </Fragment>
      )}
      {dtype !== "desktop" && (
        <Fragment>
          <section
            id="newRelease"
            className={cx(c.sectionCommonM, c.sectionTopM)}
          >
            <Album latest key="latest2-desktop" discography={NEW_ALBUM1} />
          </section>
          <BackgroundMargin height={"15vh"} />
        </Fragment>
      )}

      {/* LIVE */}
      {dtype === "desktop" && (
        <Fragment>
          <section id="lives" className={cx(c.sectionCommonD, c.sectionNormal)}>
            <Title name="LIVE" />
            <Lives available={true} />
          </section>
          <SectionBlank
            imageURL={resolveNamedCoverURL("subcover-discography")}
            backgroundPosition="50% 30%"
          />
        </Fragment>
      )}
      {dtype !== "desktop" && (
        <Fragment>
          <section id="lives" className={cx(c.sectionCommonM, c.sectionNormal)}>
            <Title name="LIVE" />
            <Lives available={true} />
          </section>
          <BackgroundMargin height={"15vh"} />
        </Fragment>
      )}

      {/* DISCOGRAPHY */}
      {dtype === "desktop" && (
        <Fragment>
          <section
            id="discography"
            className={cx(c.sectionCommonD, c.sectionNormal)}
          >
            <Title name="DISCOGRAPHY" />
            <Discography />
          </section>
          <SectionBlank
            imageURL={resolveNamedCoverURL("subcover-monophoto2")}
            backgroundPosition="50% 30%"
          />
        </Fragment>
      )}
      {dtype !== "desktop" && (
        <Fragment>
          <section
            id="discography"
            className={cx(c.sectionCommonM, c.sectionNormal)}
          >
            <Title name="DISCOGRAPHY" />
            <Discography />
          </section>

          <BackgroundMargin height={"15vh"} />
        </Fragment>
      )}

      {/* PHOTO */}
      <div id="photos">
        <Photo />
      </div>

      {/* フッター */}
      <Footer id="footer" />
    </div>
  );
};

export default App;
