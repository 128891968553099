// react
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { YOUTUBE_PAGE, TWITTER_LINK, INSTAGRAM_LINK } from '../constants';
const useStyles = makeStyles(() => ({
	// icons: 左下
	icons: {
		zIndex: 2000,
		position: 'fixed',
		bottom: 30,
		left: 0,
		'-webkit-backface-visibility': 'hidden',
		'backface-visibility': 'hidden'
	},
	bottomIconButton: {
		width: '64px',
		height: '64px',
		color: '#111',
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	icon: {
		width: '45px',
		height: '45px',
		backgroundColor: 'rgba(0,0,0,0.02)',
		border: '2px solid #111',
		padding: '10px'
	}
}));

const HeaderIconsD = () => {
	const c = useStyles();
	return (
		<Fragment>
			{/* <HeaderIconsD /> */}
			<div className={c.icons}>
				<div className={c.iconWrapper}>
					<Link target="_blank" href={TWITTER_LINK}>
						<IconButton
							disableFocusRipple
							disableRipple
							className={c.bottomIconButton}
							aria-label="HeaderMenu"
						>
							<TwitterIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
				<div className={c.iconWrapper}>
					<Link target="_blank" href={INSTAGRAM_LINK}>
						<IconButton disableRipple className={c.bottomIconButton} aria-label="HeaderMenu">
							<InstagramIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
				<div className={c.iconWrapper}>
					<Link target="_blank" href={YOUTUBE_PAGE}>
						<IconButton disableRipple className={c.bottomIconButton} aria-label="HeaderMenu">
							<YouTubeIcon className={c.icon} />
						</IconButton>
					</Link>
				</div>
			</div>
		</Fragment>
	);
};
export default HeaderIconsD;
