// react
import React from "react";
// mui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  rootWithUnderline: {
    borderBottom: "solid 1px #C4C5C5",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  root: {
    marginBottom: theme.spacing(4),
  },
  gridWrapper: {
    justifyContent: "space-between",
  },
  title: {
    display: "block",
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  datePlaceWrapper: {
    marginBottom: theme.spacing(4),
  },
  date: {
    color: "#FF5763",
    fontSize: "1.1rem",
  },
  place: {
    fontFamily: "Helvetica Neue",
    fontSize: "1.1rem",
  },
  detailContents: {
    marginBottom: theme.spacing(2),
  },
  detailContent: {
    fontFamily: "Helvetica Neue",
    fontSize: "1.1rem",
    color: "#333",
    wordWrap: "break-word",
    // fontWeight: 300,
  },
  emptyDetailContennt: {
    visibility: "hidden",
    fontSize: "1.1rem",
  },
  cover: {
    border: "solid 1px #ccc",
    borderRadius: 5,
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const LiveItem = ({ live, lastItem }) => {
  const c = useStyles();
  const { title, date, place, content, cover } = live;

  // テキストを一文毎にrender
  const renderContent = (text) => {
    // テキストが空白の時は空行にする
    if (text === "") {
      return (
        <br key={`random-${Math.random()}`} />
      );
    }
    // テキストがhttpで始まる時はリンクで囲む
    if (text.startsWith("http")) {
      return (
        <Link target="_blank" key={text} href={text}>
          {text}
        </Link>
      );
    }
    return (
      <div key={text} className={c.detailContent}>
        {text}
      </div>
    );
  };

  const renderContents = () => (
    <div className={c.detailContents}>
      {content.map((el) => renderContent(el))}
    </div>
  );

  return (
    <div className={lastItem ? c.root : c.rootWithUnderline}>
      <div className={c.titleWrapper}>
        <Typography variant="h4" component="h4" className={c.title}>
          {title}
        </Typography>
      </div>
      <Grid container spacing={1} className={c.gridWrapper}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={c.datePlaceWrapper}>
            <Typography className={c.date}>{date}</Typography>
            <Typography className={c.place}>{place}</Typography>
          </div>
          {renderContents()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <img
            className={c.cover}
            src={cover}
            alt={`johnnivan's live - ${title}`}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LiveItem;
