import { createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = createMuiTheme({
  spacing: 8,
  props: {
    MuiWithWidth: {
      // Initial width property
      initialWidth: 'xs', // Breakpoint being globally set 🌎!
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Helvetica',
      'Helvetica Neue',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    // type: 'dark',
    // primary: '#111111',
    // secondnary: '#FF5763',
    // secondary: orange,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          WebkitAppearance: 'none',
          WebkitOverflowScrolling: 'touch',
          margin: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: '#fff',
          fontSize: 14,
        },
        a: {
          textDecoration: 'none',
          outline: 'none',
          '&:hover, &:focus': {
            color: 'inherit',
            textDecoration: 'none',
            outline: 'none',
          },
        },
        ol: {
          listStyle: 'none',
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginInlineStart: 0,
          marginInlineEnd: 0,
          paddingInlineStart: 0,
          WebkitMarginBefore: 0,
          WebkitMarginAfter: 0,
          WebkitMarginStart: 0,
          WebkitMarginEnd: 0,
          WebkitPaddingStart: 0,
        },
        ul: {
          listStyle: 'none',
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginInlineStart: 0,
          marginInlineEnd: 0,
          paddingInlineStart: 0,
          WebkitMarginBefore: 0,
          WebkitMarginAfter: 0,
          WebkitMarginStart: 0,
          WebkitMarginEnd: 0,
          WebkitPaddingStart: 0,
        },
        li: {
          listStyle: 'none',
        },
        '@keyframes keyframe-hide-collapsed-text': {
          '0%': {
            opacity: 1,
            transform: 'scale(1)',
            width: 190,
          },
          '99%': {
            visibility: 'inherit',
          },
          to: {
            opacity: 0,
            transform: 'scale(0)',
            visibility: 'hidden',
            width: 0.00001,
          },
        },
        '@keyframes keyframe-image-container': {
          '0%': {
            opacity: 0.1,
          },
          '100%': {
            opacity: 0.4,
          },
        },
        '[lang="ja"] input, [lang="ja"] textarea, [lang="ja"] .input-prefix, [lang="ja"] .input-suffix': {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
      },
    },
  },
});
