import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: -1,
    width: "100%",
    height: "100vh",
  },
}));

export const HeaderImageM = ({ imageURL }) => {
  const c = useStyles();
  const style = {
    backgroundImage: `url(${imageURL})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
  };
  return <div className={c.root} style={style} />;
};
