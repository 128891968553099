import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { NewAlbum } from './NewAlbum';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '90%',
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  icon: {
    width: '30px',
    height: '30px',
    opacity: 1,
  },
  closeIconButton: {
    display: 'inline-block',
    width: '56px',
    height: '56px',
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  adjustment: {
    width: '100%',
    height: '80px',
  },
}));

const DiscographyDialog = ({ toggleDrawer, state }) => {
  const c = useStyles();
  const { dialog: open, discography } = state;
  return (
    <div className={c.root}>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={toggleDrawer('dialog', false, discography)}
        aria-labelledby="form-dialog-title"
      >
        <IconButton
          onClick={toggleDrawer('dialog', false, discography)}
          className={c.closeIconButton}
          aria-label="HeaderMenu"
        >
          <CloseIcon className={c.icon} />
        </IconButton>
        <div className={c.adjustment} />
        <DialogContent className={c.dialogContent}>
          <NewAlbum key={discography ? discography.title : 'emptyItem'} kind="dialog" discography={discography} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DiscographyDialog;
