import { PHOTO_DISPLAY_COUNT } from "../constants";
const contentful = require('contentful-management')

const client = contentful.createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_MGMT_ACCESS_TOKEN,
});

export const fetchPhotos = async () => {
  try {
    const space = await client.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE)
    const environment = await space.getEnvironment(process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master")
    const entries = await environment.getEntries({'content_type': 'photos'});
    const photos = entries.items[0].fields.jsonData['en-US'];

    // console.log(photos[0].id);
    // console.log(`photos.length: ${photos.length}`)
    return photos.slice(0, PHOTO_DISPLAY_COUNT);
  } catch (error) {
    console.error('Error on fetching photos');
    return [];
  }
}