import { useState, useEffect } from "react";
import { fetchLives } from "../requests";

export const useLive = () => {
  // livesの初期値を設定
  const [lives, setLives] = useState([]);
  // livesの変更を処理
  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      const response = await fetchLives();
      if (!didCancel && response && response.length) {
        setLives(response);
      }
    };
    fetch();
    return () => {
      didCancel = true;
    };
  }, []);
  return { lives };
};
