import { useState, useEffect } from "react";
import { resolveCoverURL } from "../utils";
/*
iphone10 812 * 375
iphone8plus 736 * 414
galaxy-s5 640 * 360
iPad Pro 1024 * 1366
iPad 768 * 1024
*/

/* 参考
https://ryotarch.com/javascript/react/get-window-size-with-react-hooks/
*/



export const useDisplaySize = () => {
  const isDesktop = (width) => width >= 1080;
  const resolveDSType = (width, height) => {
    // モバイル横
    const isLandscape = (width * 10) / (height * 10) >= 1.7;
    if (isLandscape && height <= 500) return "mobile-landscape";

    // iPad横
    if (width === 1366 && height === 1024) return "ipad-landscape";
    if (width === 1024 && height === 768) return "ipad-landscape";
    // デスクトップ
    if (isDesktop(width)) {
      return "desktop";
    }
    // モバイル
    return "mobile";
  };

  const getDS = () => {
    const { innerWidth: width, innerHeight: height } = window;
    const dtype = resolveDSType(width, height);
    const coverURL = resolveCoverURL(dtype);
    return {
      width,
      height,
      dtype,
      coverURL,
      desktop: isDesktop(width),
    };
  };

  const [ds, setDS] = useState(getDS());
  useEffect(() => {
    const onResize = () => {
      setDS(getDS());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return ds;
};
