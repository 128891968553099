import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import CommentIcon from "@material-ui/icons/ChatBubbleOutline";
import LazyLoad from "react-lazyload";
import { trimString } from "../utils";

const resolvePosition = (index, isDesktop = false) => {
  const CONDITION = {
    0: "igLeftTop",
    1: "igRightTop",
    2: "igLeftBottom",
    3: "igRightBottom",
  };
  const source = index + 4;
  const prefix = isDesktop ? "D" : "M";
  const condition = source % 4;
  const classBase = CONDITION[condition];
  return `${classBase}${prefix}`;
};

/**
 * media_typeから表示する画像を解決する
 * @param {*} photo
 * @returns
 */
const getPhotoUrl = (photo) => {
  if (!photo) {
    return undefined;
  }
  if (photo.media_type === "VIDEO") {
    return photo.thumbnail_url;
  }
  return photo.media_url;
};

const bs = "8px solid #fefefe";
const bsm = "4px solid #fefefe";
const useStyles = makeStyles((theme) => ({
  // CONCERN: photo item
  instagram: {
    position: "relative",
    zIndex: 1,
    width: "100%",
    paddingTop: "100%",
    border: 0,
    // margin: -1,
    padding: 0,
    cursor: "pointer",
    "&:hover, &$focusVisible": {
      "& $backdrop": {
        opacity: 1,
      },
      "& $instagramInteraction": {
        opacity: 1,
      },
      "& $instagramInteractionSection": {
        opacity: 1,
      },
      "& $instagramText": {
        opacity: 1,
        transition: theme.transitions.create(["opacity"], {
          duration: theme.transitions.duration.complex,
          delay: 3,
        }),
      },
    },
  },
  // CONCERN: photo item on hover
  backdrop: {
    paddingTop: "100%",
    margin: -1,
    padding: 0,
    zIndex: 2,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(24,60,91,.8)",
    width: "100%",
    height: "100%",
    opacity: 0,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
      delay: 1,
    }),
  },
  // ok
  instagramInteraction: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%", // widthの指定でコンテンツが中央揃えになる
    color: "#fefefe",
    position: "absolute",
    opacity: 0,
    top: "25%",
    [theme.breakpoints.up("md")]: {
      fontWeight: "lighter",
      fontSize: "1.5rem",
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
      delay: 2,
    }),
  },
  // ok
  instagramInteractionSection: {
    display: "flex",
  },
  // ok
  instagramText: {
    opacity: 0,
    zIndex: 3,
    position: "absolute",
    left: "50%",
    color: "#fefefe",
    cursor: "pointer",
    "-ms-transform": "translate(-50%,-50%)",
    "-webkit-transform": "translate(-50%,-50%)",
    // 要素を横並びにするため
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
      delay: 3,
    }),
    [theme.breakpoints.up("md")]: {
      top: "50%",
      width: "inherit",
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      top: "60%",
      fontSize: "0.8rem",
      width: "inherit",
    },
  },
  // ok
  instagramTextContent: {
    textAlign: "center",
  },
  // ok
  instagramIcon: {
    [theme.breakpoints.up("md")]: {
      width: "1.5rem",
      height: "1.5rem",
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "1rem",
      height: "1rem",
      marginRight: theme.spacing(0.5),
    },
  },
  // ok
  instagramIconComment: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1px", // アイコンの微調整。px使用を例外的に許可。
    },
  },
  // CONCERN: photo item by position
  igLeftTopM: {
    borderRight: bsm,
    borderBottom: bsm,
  },
  igRightTopM: {
    borderLeft: bsm,
    borderBottom: bsm,
  },
  igLeftBottomM: {
    borderRight: bsm,
    borderTop: bsm,
  },
  igRightBottomM: {
    borderLeft: bsm,
    borderTop: bsm,
  },
  igLeftTopD: {
    borderRight: bs,
  },
  igRightTopD: {
    borderRight: bs,
  },
  igLeftBottomD: {
    borderRight: bs,
  },
  igRightBottomD: {
    // marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(1),
  },
}));

export const PhotoItem = ({ photo, index, onToggle }) => {
  const c = useStyles();

  const desktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const kind = desktop ? "dialog" : "drawer";
  const positionClass = resolvePosition(index, desktop);
  const photoUrl = getPhotoUrl(photo);
  const photoStyle = {
    backgroundImage: photoUrl ? `url(${photoUrl})` : "#111",
    backgroundSize: "cover",
  };
  const caption = trimString(photo.caption, 100);
  return (
    <Grid key={`instagram-${index}`} item xs={6} sm={6} md={3} lg={3}>
      <LazyLoad>
        <div
          className={cx(c.instagram, c[positionClass])}
          style={photoStyle}
          onClick={onToggle(kind, true, photo)}
        >
          <div className={c.backdrop}>
            <div className={c.instagramInteraction}>
              {/* ホバー: いいね数 */}
              <div className={c.instagramInteractionSection}>
                <FavoriteIcon
                  className={cx(c.instagramIcon, c.instagramIconHeart)}
                />
                <div className={c.instagramInteractionText}>
                  {photo.like_count}
                </div>
              </div>

              {/* ホバー: コメント数 */}
              <div className={c.instagramInteractionSection}>
                <CommentIcon
                  className={cx(c.instagramIcon, c.instagramIconComment)}
                />
                <div className={c.instagramInteractionText}>
                  {photo.comments_count}
                </div>
              </div>
            </div>
          </div>
          {/* ホバー: コメント */}
          <div className={c.instagramText}>
            <p className={c.instagramTextContent}>{caption}</p>
          </div>
        </div>
      </LazyLoad>
    </Grid>
  );
};
