import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import InstagramEmbed from "react-instagram-embed";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { INSTAGRAM_CLIENT_TOKEN } from "../constants";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    width: "100%",
    height: "56px",
  },
  closeIconButton: {
    boxSizing: "border-box",
    // zIndex: 100,
    width: "56px",
    height: "56px",
    position: "absolute",
    top: 0,
    right: 0,
    // color: '#FEFEFE',
    borderRadius: 0,
    color: "rgba( 0, 0, 0, 0.54 )",
    backgroundColor: "#c8c8c8",
    boxShadow: "0px 6px 12px #00000029",
  },
  headerAdjustment: {
    width: "100%",
    height: "56px",
  },
  instagramEmbed: {
    mamrgin: theme.spacing(1),
  },
  adjustment: {
    background: "#111",
    width: "100%",
    height: "160px",
  },
  bottommAdjustment: {
    width: "100%",
    height: "320px",
  },
}));

const PhotoDrawer = ({ state, toggleDrawer }) => {
  const c = useStyles();
  const { photo } = state;

  return (
    <Drawer
      anchor="bottom"
      open={state.drawer}
      fullScreen
      onClose={toggleDrawer("drawer", false, photo)}
      transitionDuration={{ enter: 500, exit: 250 }}
      disableScrollLock
      className={c.root}
    >
      <div className={c.header}>
        <IconButton
          onClick={toggleDrawer("drawer", false, photo)}
          className={c.closeIconButton}
          aria-label="HeaderMenu"
        >
          <CloseIcon className={c.icon} />
        </IconButton>
      </div>
      <div className={c.headerAdjustment} />
      <div className={c.instagramEmbed}>
        <InstagramEmbed
          url={photo.permalink}
          clientAccessToken={INSTAGRAM_CLIENT_TOKEN}
          maxWidth={300}
          hideCaption={false}
          containerTagName="div"
          protocol=""
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        />
      </div>
      {/* <div className={c.adjustment} />
        <div className={c.bottomAdjustment} /> */}
    </Drawer>
  );
};

export default PhotoDrawer;
